import { render, staticRenderFns } from "./IOS.vue?vue&type=template&id=720cafd8&scoped=true"
var script = {}
import style0 from "./IOS.vue?vue&type=style&index=0&id=720cafd8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720cafd8",
  null
  
)

export default component.exports